import React from 'react';
import {
    AppBar,
    Toolbar,
    Container,
    Grid,
    Paper,
    Typography,
    IconButton,
    MenuItem,
    CircularProgress,
    LinearProgress,
    TextField,
    Button,
    Snackbar,
    SnackbarContent,
    FormControl,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Chip
} from '@material-ui/core';
import {
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    Tooltip, 
    Legend,
    ResponsiveContainer
} from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '@material-ui/icons/GetApp';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ReactTimeout from 'react-timeout';
import Numeral from 'numeral';
import $ from 'jquery';
import logo from './../assets/logo_white.png';
import CSS from '../css/Style';
import _ from 'underscore';
import { CSVDownload } from "react-csv";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            accountInfoUpdated: false,
            donorSearchVisible: false,
            donorEditingVisible: false,
            message: null,
            keyword: "",
            snackbarStyle: {backgroundColor: 'darkred'},
            target: "all",
            scene: "funds_raised",
            schools: [],
            all_schools: [],
            formattedSchools: [],
            formattedSchoolMenuItems: [<MenuItem key="all" dense value="all">All Schools</MenuItem>],
            donors: [],
            formattedDonors: [],
            csvData: [],
            csvLinkVisible: false,
            data: {
                funds_raised: 0,
                contributions: 0,
                donors: 0
            },
            current_donor: {
                id: "",
                name: "",
                active: ""
            },
            chart_data: {
                funds_raised: [
                    {name: '', "Funds Raised": 0},
                    {name: '', "Funds Raised": 0},
                    {name: '', "Funds Raised": 0},
                    {name: '', "Funds Raised": 0},
                    {name: '', "Funds Raised": 0},
                    {name: '', "Funds Raised": 0},
                ],
                round_up_contributions: [
                    {name: '', "Round Ups": 0},
                    {name: '', "Round Ups": 0},
                    {name: '', "Round Ups": 0},
                    {name: '', "Round Ups": 0},
                    {name: '', "Round Ups": 0},
                    {name: '', "Round Ups": 0},
                ],
                one_time_contributions: [
                    {name: 'May 2019', "One-Time Donations": 25},
                    {name: 'Jun 2019', "One-Time Donations": 37},
                    {name: 'Jul 2019', "One-Time Donations": 44},
                    {name: 'Aug 2019', "One-Time Donations": 75},
                    {name: 'Sep 2019', "One-Time Donations": 11},
                    {name: 'Oct 2019', "One-Time Donations": 26},
                ]
            }
        };

        $("html, body").scrollTop(0);

        // GET TOTAL FUNDS RAISED
        // GET TOTAL CONTRIBUTIONS
        // GET TOTAL DONORS
        props.DB.collection('schools').get().then((schoolsSnapshot) => {
            let schools = [];
            let formattedSchools = [];
            let formattedSchoolMenuItems = [<MenuItem key="all" dense value="all">All Schools</MenuItem>];
            let funds_raised = 0;
            let contributions = 0;
            let donors = 0;

            schoolsSnapshot.forEach((schoolDoc) => {
                let school = schoolDoc.data();
                school.id = schoolDoc.id;

                funds_raised += school.funds_raised;
                contributions += school.contributions;
                donors += school.donors;
                schools.push(school);

                formattedSchoolMenuItems.push(<MenuItem key={school.id} dense value={school.id}>{school.name}</MenuItem>);
            })

            schools = _.sortBy(schools, 'funds_raised').reverse();
            
            // FORMAT SCHOOLS
            schools.forEach((school, schoolIndex) => {
                formattedSchools.push(
                    <TableRow key={school.id} style={schoolIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                        <TableCell style={{textAlign: 'left'}}>{schoolIndex+1}</TableCell>
                        <TableCell style={{textAlign: 'left'}}>{school.name}</TableCell>
                        <TableCell style={{textAlign: 'left'}}>{Numeral(school.donors).format('0,0')}</TableCell>
                        <TableCell style={{textAlign: 'left'}}>{Numeral(school.contributions).format('0,0')}</TableCell>
                        <TableCell style={{textAlign: 'left'}}>{Numeral(school.funds_raised/100).format('$0,0.00')}</TableCell>
                    </TableRow>
                )
            })

            this.setState({
                loading: false,
                schools: schools,
                formattedSchools: formattedSchools,
                formattedSchoolMenuItems: formattedSchoolMenuItems,
                data: {
                    funds_raised: funds_raised, 
                    contributions: contributions,
                    donors: donors
                }
            });
        }).catch((error) => {
            this.setState({
                message: error.message,
                snackbarStyle: {backgroundColor: 'darkred'},
                loading: false
            });

            this.props.setTimeout(() => this.setState({
                message: null,
                loading: false
            }), 3500);  
        })
        // GET TOTAL CONTRIBUTIONS
        // GET TOTAL DONORS
            // ACTIVE
            // PAUSED
            // INACTIVE
                // NO FUNDING SOURCE
                // NO CARDS

        this.updateTarget = this.updateTarget.bind(this);
        this.getDonors = this.getDonors.bind(this);
        this.toggleDonorSearchVisibility = this.toggleDonorSearchVisibility.bind(this);
    }

    updateTarget(school_id) {
        this.setState({loading: true});
        if (school_id === "all") {
            this.props.DB.collection('schools').get().then((schoolsSnapshot) => {
                // let schools = [];
                let funds_raised = 0;
                let contributions = 0;
                let donors = 0;
    
                schoolsSnapshot.forEach((schoolDoc) => {
                    let school = schoolDoc.data();
                    school.id = schoolDoc.id;
    
                    funds_raised += school.funds_raised;
                    contributions += school.contributions;
                    donors += school.donors;
                })
    
                this.setState({
                    loading: false,
                    target: school_id,
                    data: {
                        funds_raised: funds_raised, 
                        contributions: contributions,
                        donors: donors
                    }
                });

                if (this.state.scene === "donors") {
                    this.getDonors(school_id);
                }
            }).catch((error) => {
                this.setState({
                    message: error.message,
                    snackbarStyle: {backgroundColor: 'darkred'},
                    loading: false
                });
    
                this.props.setTimeout(() => this.setState({
                    message: null,
                    loading: false
                }), 3500);  
            })
        } else {
            this.props.DB.collection('schools').doc(school_id).get().then((schoolDoc) => {
                let school = schoolDoc.data();
                let funds_raised = 0;
                let contributions = 0;
                let donors = 0;

                funds_raised += school.funds_raised;
                contributions += school.contributions;
                donors += school.donors;
    
                this.setState({
                    loading: false,
                    target: school_id,
                    data: {
                        funds_raised: funds_raised, 
                        contributions: contributions,
                        donors: donors
                    }
                });

                if (this.state.scene === "donors") {
                    this.getDonors(school_id);
                }
            }).catch((error) => {
                this.setState({
                    message: error.message,
                    snackbarStyle: {backgroundColor: 'darkred'},
                    loading: false
                });
    
                this.props.setTimeout(() => this.setState({
                    message: null,
                    loading: false
                }), 3500);  
            })
        }
    }

    getDonors() {
        const school_id = this.props.AUTH.currentUser.uid;

        this.setState({loading: true});
        if (school_id === "all") {
            this.props.DB.collection('donors').where("school", "==", this.props.school.id).get().then((donorsSnapshot) => {
                let donors = [];
                let formattedDonors = [];
                
                donorsSnapshot.forEach((donorDoc) => {
                    let donor = donorDoc.data();
                    donor.id = donorDoc.id;
                    donors.push(donor);
                })

                donors.forEach((donor, donorIndex) => {
                    formattedDonors.push(
                        <TableRow key={donor.id} style={donorIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                            <TableCell style={{textAlign: 'left'}}>{donor.name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.email}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.school_name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.class_year}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.contributions).format('0,0')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.funds_raised/100).format('$0,0.00')}</TableCell>
                            <TableCell style={{fontStyle: 'italic', textAlign: 'left'}}>{donor.status}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.active ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}</TableCell>
                        </TableRow>
                    )
                })                
    
                this.setState({
                    loading: false,
                    donors: donors,
                    formattedDonors: formattedDonors,
                    school_id: school_id
                });
            }).catch((error) => {
                this.setState({
                    message: error.message,
                    snackbarStyle: {backgroundColor: 'darkred'},
                    loading: false
                });
    
                this.props.setTimeout(() => this.setState({
                    message: null,
                    loading: false
                }), 3500);  
            })
        } else {
            this.props.DB.collection('donors').where("school", "==", school_id).get().then((donorsSnapshot) => {
                let donors = [];
                let formattedDonors = [];
    
                donorsSnapshot.forEach((donorDoc) => {
                    let donor = donorDoc.data();
                    donor.id = donorDoc.id;

                    donors.push(donor);
                })


                donors.forEach((donor, donorIndex) => {
                    formattedDonors.push(
                        <TableRow key={donor.id} style={donorIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                            <TableCell style={{textAlign: 'left'}}>{donor.name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.email}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.school_name}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.class_year}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.contributions).format('0,0')}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{Numeral(donor.funds_raised/100).format('$0,0.00')}</TableCell>
                            <TableCell style={{fontStyle: 'italic', textAlign: 'left'}}>{donor.status}</TableCell>
                            <TableCell style={{textAlign: 'left'}}>{donor.active ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}</TableCell>
                        </TableRow>
                    )
                })   
    
                this.setState({
                    loading: false,
                    donors: donors,
                    formattedDonors: formattedDonors,
                    school_id: school_id
                });
            }).catch((error) => {
                this.setState({
                    message: error.message,
                    snackbarStyle: {backgroundColor: 'darkred'},
                    loading: false
                });
    
                this.props.setTimeout(() => this.setState({
                    message: null,
                    loading: false
                }), 3500);  
            })
        }
    }

    filterDonors(keyword) {
        let donors = this.state.donors;
        let formattedDonors = [];

        donors = _.filter(donors, (donor) => {

            let match = (donor.name.toLowerCase().indexOf(keyword) !== -1 || donor.email.toLowerCase().indexOf(keyword) !== -1 || donor.id.toLowerCase().indexOf(keyword) !== -1);

            return match; 
        });

        donors.forEach((donor, donorIndex) => {
            formattedDonors.push(
                <TableRow key={donor.id} style={donorIndex % 2 === 0 ? {backgroundColor: '#ccc'} : {backgroundColor: '#fff'}}>
                    <TableCell style={{textAlign: 'left'}}>{donor.name}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{donor.school_name}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{donor.class_year}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{Numeral(donor.contributions).format('0,0')}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{Numeral(donor.funds_raised/100).format('$0,0.00')}</TableCell>
                    <TableCell style={{fontStyle: 'italic', textAlign: 'left'}}>{donor.status}</TableCell>
                    <TableCell style={{textAlign: 'left'}}>{donor.active ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}</TableCell>
                </TableRow>
            )
        })

        this.setState({formattedDonors: formattedDonors, donorSearchVisible: false});
    }

    formatSchoolMenuItems(schools) {
        let formattedSchoolMenuItems = [];
        schools.forEach((school) => {
            formattedSchoolMenuItems.push(<MenuItem value={school.id}>{school.name}</MenuItem>);
        })
    }
    
    toggleDonorSearchVisibility() {
        this.setState({donorSearchVisible: !this.state.donorSearchVisible});
    }

    toggleDonorEditingVisibility() {
        this.setState({donorEditingVisible: !this.state.donorEditingVisible});
    }

    editDonor(donor) {
        this.setState({current_donor: donor, donorEditingVisible: true});
    }

    updateDonor() {
        this.setState({loading: true});

        let donor = this.state.current_donor;
        let donor_id = donor.id;

        this.props.DB.collection("donors").doc(donor_id).update({
            active: donor.active
        }).then(() => {
            this.setState({donorEditingVisible: false, loading: false});
            this.getDonors(this.state.school_id);
        }).catch((error) => {
            this.setState({message: error.message, loading: false})
        })
    }

    createDonorCSV() {
        let donors = this.state.donors;
        let formattedCSV = [["Name", "Email", "School", "Class", "Contributions", "Funds Raised", "Status", "Active"]];
        donors.forEach((donor) => {
            formattedCSV.push([donor.name, donor.email, donor.school_name, donor.class_year, donor.contributions, donor.funds_raised, donor.status, donor.active]);
        })

        this.setState({csvData: formattedCSV, csvLinkVisible: true});
    }

    createPayoutAccount() {
        this.setState({loading: true});

        fetch('https://us-central1-hbcu-change.cloudfunctions.net/createConnectAccount', {
            method: 'POST',
            header: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
                email: this.props.school.email,
                id: this.props.school.id
            })
        }).then((response) => {
            response.text()
            .then((data) => {
                window.location.href = data;
            })
            .catch((error) => {
                this.setState({
                    message: error.message,
                    snackbarStyle: {backgroundColor: 'red'},
                    loading: false
                });
    
                this.props.setTimeout(() => this.setState({
                    message: null,
                    loading: false
                }), 3500);  
            })
        })
        .catch((error) => {
            this.setState({
                message: error.message,
                snackbarStyle: {backgroundColor: 'red'},
                loading: false
            });

            this.props.setTimeout(() => this.setState({
                message: null,
                loading: false
            }), 3500);  
        })
    }

    managePayoutAccount() {
        this.setState({loading: true});

        fetch('https://us-central1-hbcu-change.cloudfunctions.net/createConnectLoginLink', {
            method: 'POST',
            header: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
                id: this.props.school.payoutAccount.id
            })
        }).then((response) => {
            response.text().then((result) => {
                if (result.error === "Cannot create a login link for an account that has not completed onboarding.") {
                    this.createPayoutAccount();
                } else if (result.error) {
                    this.setState({
                        message: result.error.message,
                        snackbarStyle: {backgroundColor: 'red'},
                        loading: false
                    });
        
                    this.props.setTimeout(() => this.setState({
                        message: null,
                        loading: false
                    }), 5000);  
                } else {
                    window.open(result, '_blank');
                }
            })
        }).catch((error) => {
            this.setState({
                message: error.message,
                snackbarStyle: {backgroundColor: 'red'},
                loading: false
            });

            this.props.setTimeout(() => this.setState({
                message: null,
                loading: false
            }), 3500);  
        })
    }

    viewPayouts(stripe_account_id) {
        this.setState({loading: true});
        fetch('https://us-central1-hbcu-change.cloudfunctions.net/createStripeConnectLoginLink', {
            method: 'POST',
            header: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
                school_stripe_account_id: stripe_account_id
            })
        }).then((response) => {
            return response.json();
        }).then((data) => {
            console.log(data);
            window.location.href = data.url;
        });
    }

    updateAccountInfo() {
        if (!this.state.emailAddress || !this.state.emailConfirmation || !this.state.securePassword || !this.state.passwordConfirmation) {
            this.setState({
                message: 'All Fields Required',
                snackbarStyle: {backgroundColor: 'red'},
                loading: false
            });

            this.props.setTimeout(() => this.setState({
                message: null,
                loading: false
            }), 3500); 
        } else if (this.state.emailAddress !== this.state.emailConfirmation) {
            this.setState({
                message: 'Email Mismatch',
                snackbarStyle: {backgroundColor: 'red'},
                loading: false
            });

            this.props.setTimeout(() => this.setState({
                message: null,
                loading: false
            }), 3500); 
        } else if (this.state.securePassword !== this.state.passwordConfirmation) {
            this.setState({
                message: 'Password Mismatch',
                snackbarStyle: {backgroundColor: 'red'},
                loading: false
            });

            this.props.setTimeout(() => this.setState({
                message: null,
                loading: false
            }), 3500); 
        } else {
            this.setState({loading: true});

            this.props.AUTH.currentUser.updatePassword(this.state.securePassword)
            .then(() => {
                this.props.AUTH.currentUser.updateEmail(this.state.emailAddress)
                .then(() => {
                    this.props.DB.collection("schools").doc(this.props.school.id).update({
                        email: this.state.emailAddress
                    }).then(() => {
                        this.setState({
                            message: 'Info Updated Successfully',
                            snackbarStyle: {backgroundColor: 'green'},
                            accountInfoUpdated: true,
                        });
            
                        this.props.setTimeout(() => this.setState({
                            message: null,
                            loading: false
                        }), 2500);
                    }) 
                })
                .catch((error) => {
                    this.setState({
                        message: error.message,
                        snackbarStyle: {backgroundColor: 'red'},
                        loading: false
                    });
        
                    this.props.setTimeout(() => this.setState({
                        message: null,
                        loading: false
                    }), 5000);  
                })
            })
            .catch((error) => {
                this.setState({
                    message: error.message,
                    snackbarStyle: {backgroundColor: 'red'},
                    loading: false
                });
    
                this.props.setTimeout(() => this.setState({
                    message: null,
                    loading: false
                }), 3500);  
            })
        }
    }

    render() {
        const { classes } = this.props;

        return(
            <React.Fragment>
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={this.state.message !== null}
                    onClose={() => this.setState({message: null})}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                >
                    <SnackbarContent
                        style={this.state.snackbarStyle}
                        message={this.state.message}
                    />    
                </Snackbar>
                <AppBar style={{backgroundColor: '#222'}}>
                    <Toolbar>
                        <Container style={{display: 'flex'}}>
                            <img style={{height: '2rem', margin: '0.5rem 1rem 0.5rem 0'}} src={logo} alt="logo"/>
                            <Typography variant="h1" style={{flexGrow: 1, fontSize: '1rem', fontWeight: '700', lineHeight: '3', color: '#fff'}}>
                                {this.props.school.name}
                            </Typography>
                            <Button disabled={this.state.loading} onClick={() => this.setState({scene: "funds_raised"})} className={classes.headerNavItem}>Funds Raised</Button>
                            <Button disabled={this.state.loading} onClick={() => {this.getDonors(this.props.AUTH.currentUser.uid); this.setState({scene: "donors"})}} className={classes.headerNavItem}>Donors</Button>
                            <Button disabled={this.state.loading} onClick={() => {this.setState({scene: "schools"})}} className={classes.headerNavItem}>Leaderboard</Button>
                            {this.props.school.payoutAccount === undefined && (this.props.school.email.indexOf('@hbcuchange.com') < 0 || this.state.accountInfoUpdated === true) && <Button disabled={this.state.loading} style={!this.state.loading ? {color: 'red'} : null} onClick={() => this.createPayoutAccount()} className={classes.headerNavItem}>Setup Payouts</Button>}
                            {this.props.school.payoutAccount && (this.props.school.email.indexOf('@hbcuchange.com') < 0 || this.state.accountInfoUpdated === true) && <Button disabled={this.state.loading} onClick={() => this.managePayoutAccount()} className={classes.headerNavItem}>Manage Payouts</Button>}
                            <Button onClick={() => this.props.unauthorize()} className={classes.headerNavItem} disabled={this.state.loading}>Log Out</Button>
                        </Container>
                    </Toolbar>
                </AppBar>
                <Container>
                    {/* FUNDS RAISED SCENE */}
                    {(this.props.school.email.indexOf('@hbcuchange.com') > -1 && this.state.accountInfoUpdated === false) && this.state.scene === "funds_raised" && <Grid container spacing={2} style={{paddingTop: '6rem', textAlign: 'center', maxWidth: '20rem', margin: '0 auto'}}>
                        <Grid xs={12} style={{display: 'flex', paddingBottom: 0, justifyContent: 'center'}} item>
                            {this.state.loading ? <CircularProgress style={{color: '#58c26e'}}/> : <div><Typography className={classes.pageTitle} style={{flexGrow: 1, marginBottom: 0}}>Update Your Info</Typography><Typography style={{fontFamily: 'helvetica', fontWeight: '300', lineHeight: 1}}>Once complete, you will be able to set your account up to receive payouts.</Typography></div>}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={(event) => this.setState({emailAddress: event.target.value})} id="outlined-basic" type="email-address" label="Email Address" variant="outlined" fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={(event) => this.setState({emailConfirmation: event.target.value})} id="outlined-basic" type="email-address" label="Email Confirmation" variant="outlined" fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={(event) => this.setState({securePassword: event.target.value})} id="outlined-basic" type="password" label="Secure Password" variant="outlined" fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={(event) => this.setState({passwordConfirmation: event.target.value})} id="outlined-basic" type="password" label="Confirm Password" variant="outlined" fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={this.state.loading} variant="contained" block style={{width: '100%', backgroundColor: '#222', color: '#f5f5f5'}} onClick={() => this.updateAccountInfo()}>Update Info</Button>
                        </Grid>
                    </Grid>}
                    {(this.props.school.email.indexOf('@hbcuchange.com') < 0 || this.state.accountInfoUpdated === true) && this.state.scene === "funds_raised" && <Grid container spacing={6} style={{paddingTop: '6rem'}}>
                        <Grid xs={12} style={{display: 'flex', paddingBottom: 0, marginBottom: 0}} item>
                            <Typography className={classes.pageTitle} style={{flexGrow: 1, marginRight: '1.5rem'}}>Funds Raised</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} container>
                            <Paper className={classes.statCard} style={{textAlign: 'center'}} elevation={1}>
                                <Typography variant="h1" className={classes.stat} style={{fontSize: '4rem', fontFamily: 'helvetica', fontWeight: '200'}}>{this.state.loading ? <CircularProgress style={{color: '#58c26e'}}/> : Numeral(this.props.school.funds_raised/100).format('$0,0.00')}</Typography>
                                <Typography varient="subtitle2" className={classes.statDescription}>Total Funds Raised</Typography>
                            </Paper>
                            {/* <Grid item xs={12} md={8} style={{width: '100%'}}>
                                <Paper style={{width: '100%', padding: '1rem', backgroundColor: '#fff'}} elevation={1}>
                                    <ResponsiveContainer width={'99%'} height={300}>
                                        <BarChart data={this.state.chart_data.funds_raised} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip formatter={(value) => {return Numeral(value).format('$0,0.00')}} />
                                            <Legend />
                                            <Bar dataKey="Funds Raised" fill="#5bc977" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Paper>
                            </Grid> */}
                        </Grid>
                        <Grid item xs={12} md={6} container>
                            <Paper className={classes.statCard} style={{textAlign: 'center'}} elevation={1}>
                                <Typography variant="h1" className={classes.stat} style={{fontSize: '4rem', fontFamily: 'helvetica', fontWeight: '200'}}>{this.state.loading ? <CircularProgress style={{color: '#58c26e'}}/> : Numeral(this.props.school.contributions).format('0,0')}</Typography>
                                <Typography varient="subtitle2" className={classes.statDescription}>Contributions</Typography>
                            </Paper>
                        </Grid>
                    </Grid>}

                    {/* DONORS SCENE */}
                    {this.state.scene === "donors" && <Grid container spacing={6} style={{paddingTop: '6rem'}}>
                        <Grid xs={12} style={{display: 'flex', paddingBottom: 0, marginBottom: 0}} item>
                            <Typography className={classes.pageTitle} style={{flexGrow: 1, marginRight: '1.5rem'}}>Donors <small style={{color: '#58c26e', fontWeight: '200'}}>{this.state.donors.length}</small></Typography>
                            <IconButton
                                onClick={() => this.toggleDonorSearchVisibility()}
                                style={{color: '#58c26e', marginRight: '1rem'}}
                            >
                                <SearchIcon/>
                            </IconButton>
                            <IconButton
                                onClick={() => this.createDonorCSV()}
                                style={{color: '#243b52', marginRight: '1rem'}}
                            >
                                <DownloadIcon/>
                            </IconButton>
                        </Grid>
                        {this.state.keyword && <Grid item xs={12}>
                            Filters: <Chip label={this.state.keyword} onDelete={() => {
                                this.filterDonors("");
                                this.setState({keyword: ""});
                            }}/>
                        </Grid>}
                        <Grid item xs={12}>
                            <Table style={{backgroundColor: '#fff', borderWidth: '1px'}}>
                                <TableHead style={{backgroundColor: '#243b52'}}>
                                    <TableRow>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Name</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Email</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>School</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Class</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Contributions</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Funds Raised</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Status</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Active</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.formattedDonors}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>}

                    {/* SCHOOLS SCENE */}
                    {this.state.scene === "schools" && <Grid container spacing={6} style={{paddingTop: '6rem'}}>
                        <Grid xs={12} style={{display: 'flex', paddingBottom: 0, marginBottom: 0}} item>
                            <Typography className={classes.pageTitle} style={{flexGrow: 1, marginRight: '1.5rem'}}>Schools <small style={{color: '#58c26e', fontWeight: '200'}}>{this.state.schools.length}</small></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Table style={{backgroundColor: '#fff', borderWidth: '1px'}}>
                                <TableHead style={{backgroundColor: '#243b52'}}>
                                    <TableRow>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Rank</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>School</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Donors</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Contributions</TableCell>
                                        <TableCell style={{color: '#fff', fontWeight: '700', textAlign: 'left'}}>Funds Raised</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.formattedSchools}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>}
                    <Dialog open={this.state.donorSearchVisible} onClose={() => this.toggleDonorSearchVisibility()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{padding: '12px 24px 0px'}}>Search</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Find donors by email, name, or id.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                variant="outlined"
                                placeholder="email, name, or id"
                                value={this.state.keyword}
                                onChange={(event) => this.setState({keyword: event.target.value})}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button style={{textTransform: 'none'}} onClick={() => {this.filterDonors(""); this.setState({keyword: ""}); this.toggleDonorSearchVisibility()}}>
                                Cancel
                            </Button>
                            <Button style={{textTransform: 'none', backgroundColor: '#58c16c', color: '#fff'}} onClick={() => this.filterDonors(this.state.keyword)}>
                                Search
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* EDIT DONOR MODAL */}
                    <Dialog open={this.state.donorEditingVisible} onClose={() => this.toggleDonorEditingVisibility()} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" style={{padding: '12px 24px 0px'}}>Manage Donor</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.current_donor.name}<br/>
                                {this.state.current_donor.email}
                            </DialogContentText>
                            {this.state.loading && <LinearProgress/>}
                            <Select
                                value={this.state.current_donor.active}
                                onChange={(event) => this.setState({current_donor: {...this.state.current_donor, active: event.target.value}})}
                                style={{backgroundColor: '#fff'}}
                                label="Active"
                                fullWidth
                                variant="outlined"
                            >
                                <MenuItem key={true} dense value={true}>True</MenuItem>
                                <MenuItem key={false} dense value={false}>False</MenuItem>
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={this.state.loading} style={{textTransform: 'none'}} onClick={() => this.toggleDonorEditingVisibility()}>
                                Cancel
                            </Button>
                            <Button disabled={this.state.loading} style={{textTransform: 'none', backgroundColor: '#58c16c', color: '#fff'}} onClick={() => this.updateDonor()}>
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {this.state.csvLinkVisible && <CSVDownload data={this.state.csvData} target="_blank" />}
                </Container>
            </React.Fragment>
        )
    }
}

export default withStyles(CSS)(ReactTimeout(Dashboard));
