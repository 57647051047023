import React from 'react';
import LogIn from './components/LogIn';
import Dashboard from './components/Dashboard';
import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';
import 'url-search-params-polyfill';

// import DATA from './slugs';


const CONFIG = {
  apiKey: "AIzaSyBUZPZDE45UHKZhwjQiJ2_E1erBegMylhI",
  authDomain: "hbcu-change.firebaseapp.com",
  databaseURL: "https://hbcu-change.firebaseio.com",
  projectId: "hbcu-change",
  storageBucket: "hbcu-change.appspot.com",
  messagingSenderId: "434200357772",
  appId: "1:434200357772:web:de94971f461ba23e86c4a9",
  measurementId: "G-64JRHW5BC3"
};

firebase.initializeApp(CONFIG);
const AUTH = firebase.auth();
const STORAGE = firebase.storage().ref();
const DB = firebase.firestore();


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scene: "auth",
      authorized: false,
      message: null,
      school: {
        name: ""
      }
    }

    // DATA.forEach((school) => {
    //   // DB.collection('donors').doc(demo_donor.id).set(demo_donor);
    //   let school_campus_slug = `${school.slug}_campus.jpg`;
    //   let school_logo_slug = `${school.slug}_logo.jpg`;
    //   let school_campus_image_url = "";
    //   let school_logo_image_url = "";

    //   STORAGE.child(school_campus_slug).getDownloadURL()
    //   .then((campus_url) => {
    //     school_campus_image_url = campus_url;
    //   })
    //   .then(() => {
    //     STORAGE.child(school_logo_slug).getDownloadURL()
    //     .then((logo_url) => {
    //       school_logo_image_url = logo_url;

    //       DB.collection("schools").doc(school.id).update({
    //         "style_guide.hero": school_campus_image_url,
    //         "style_guide.logo": school_logo_image_url
    //       });

    //     })
    //   })
    // })

    this.authorize = this.authorize.bind(this);
    this.unauthorize = this.unauthorize.bind(this);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        DB.collection('schools').doc(user.uid).onSnapshot((schoolDoc) => {
          if (user.uid && schoolDoc.exists && schoolDoc.data().active) {
              let school = schoolDoc.data();
              school.id = schoolDoc.id;
              this.authorize(school);
          }
        })

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let stripe_connect_code = params.get('code');
    
        if (stripe_connect_code) {
          fetch('https://us-central1-hbcu-change.cloudfunctions.net/setupPayouts', {
            method: 'POST',
            header: {
              'Content-Type': 'application/json'
            },
            mode: 'cors',
            body: JSON.stringify({
              school_id: user.uid,
              authorization_code: stripe_connect_code
            })
          });
        }
      }
    });
  }

  authorize(schoolData) {
    this.setState({
      authorized: true,
      scene: 'dashboard',
      school: schoolData
    });
  }

  unauthorize() {
    AUTH.signOut().then(() => {
      this.setState({authorized: false, scene: 'auth'})
    }).catch((error) => {
      this.setState({message: error.message});
    })
  }

  render() {
    return (
      <div style={{backgroundColor: '#f5f5f5', minHeight: '100vh'}}>
        {this.state.scene === "auth" && <LogIn AUTH={AUTH} DB={DB} authorize={this.authorize}  unauthorize={this.unauthorize} message={this.state.message}/>}
        {(this.state.scene === "dashboard" && AUTH.currentUser && this.state.authorized) && <Dashboard AUTH={AUTH} DB={DB} school={this.state.school} unauthorize={this.unauthorize}/>}
      </div>
    );
  }
}

export default App;
