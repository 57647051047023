import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';


class Root extends React.Component {
    render() {
        return(
            <BrowserRouter basename={'/'} >
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={App}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/stripe`} component={App}/>
                </Switch>
          </BrowserRouter>
        );
    }
}


ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
